import * as CookieConsent from "vanilla-cookieconsent";
import "~/vanilla-cookieconsent/dist/cookieconsent.css";

const optInVideoService = function (serviceName = '') {
    window.dispatchEvent(new CustomEvent('cookieconsent-optinvideoservice', { detail: { service: serviceName } }))
}
const optOutVideoService = function (serviceName = '') {
    window.dispatchEvent(new CustomEvent('cookieconsent-optoutvideoservice', { detail: { service: serviceName } }))
}

const revision = 2

const cc = CookieConsent

window.cc = cc

cc.run({
    mode: 'opt-in',
    revision,
    guiOptions: {
        consentModal: {
            layout: 'box wide',
            position: 'middle center',
        }
    },

    disablePageInteraction: true,

    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
        marketing: {
            enabled: false,
            readOnly: false,

            services: {
                mailchimp: {
                    label: 'Mailchimp',
                    onAccept: () => optInVideoService('mailchimp'),
                    onReject: () => optOutVideoService('mailchimp'),
                },
            },
        },
        analytics: {
            enabled: false,
            readOnly: false,
        }
    },
    language: {
        default: 'de',

        translations: {
            de: {
                consentModal: {
                    title: 'Wir nutzen Cookies',
                    description: 'Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies.',
                    acceptAllBtn: 'Alle akzeptieren',
                    acceptNecessaryBtn: 'Alle ablehnen',
                    showPreferencesBtn: 'Individuelle Konfiguration'
                },
                preferencesModal: {
                    title: 'Cookie Einstellungen',
                    acceptAllBtn: 'Alle akzeptieren',
                    acceptNecessaryBtn: 'Alle ablehnen',
                    savePreferencesBtn: 'Aktuelle Einstellungen speichern',
                    closeIconLabel: 'Cookie Einstellungen schließen',
                    sections: [
                        {
                            title: "Cookie Nutzung",
                            description: 'Wir verwenden Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie diese an- oder abmelden möchten.'
                        }, {
                            title: 'Erforderliche Cookies',
                            description: 'Diese Dienste sind für die korrekte Funktion dieser Website unerlässlich. Sie können nicht deaktiviert werden, da die Website sonst nicht richtig funktionieren würde.',
                            linkedCategory: 'necessary'
                        }, {
                            title: 'Marketing',
                            description: '',
                            linkedCategory: 'marketing'
                        }, {
                            title: 'Statistiken',
                            description: 'Diese Cookies sind erforderlich, um anonyme Besucherstatistiken zu erfassen, die uns dabei unterstützen, die Nutzung unserer Internetseite zu verstehen.',
                            linkedCategory: 'analytics'
                        }, {
                            title: 'Mehr Informationen',
                            description: 'Informationen zur Datenverarbeitung finden Sie in der <a href="/page/data-confidentiality">Datenschutzerklärung</a>.'
                        }
                    ]
                }
            }
        }
    }
})