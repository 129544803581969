import.meta.glob([
    '../images/**',
]);
import Masonry from 'masonry-layout'
import './modules/CConsent'

import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';

import { iframeResize } from 'iframe-resizer'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import refreshImages from './functions/refreshImages';

window.Alpine = Alpine

Alpine.data('masonry', () => ({
    init() {
        this.$nextTick(() => {
            new Masonry(this.$el, {
                columnWidth: 200,
                itemSelector: '.masonry__item',
                columnWidth: '.masonry__sizer',
                percentPosition: true,
                transitionDuration: 0,
                gutter: 0,
            })

            setTimeout(() => {
                refreshImages()
            }, 500);
        })
    }
}))

Alpine.data('ticketModal', () => ({
    openTicketModal() {

        if (window.innerWidth <= 640) {
            window.open(this.$el.href, '_blank').focus();

            return;
        }

        this.$dispatch('open-cinetixx-modalbox', this.$el.href)
    }
}))

Alpine.data('cinetixxModalbox', () => ({
    show: false,
    url: null,

    init() {
        this.$watch('show', (value) => {
            if (value) {
                iframeResize({
                    minHeight: 400,
                    heightCalculationMethod: 'taggedElement',
                    checkOrigin: false,
                }, '#cinetixxFrame')

                disableBodyScroll(this.$refs.modal)
            } else {
                this.url = null

                enableBodyScroll(this.$refs.modal)
            }
        })
    }
}))

Alpine.data('navigation', () => ({
    openItem: null,
    backgroundHeight: null,
    init() {
        this.$watch('openItem', (openItem) => {
            if (!openItem) {
                this.backgroundHeight = null
                return
            }

            const dropdownElm = this.$refs[`navigation-dropdown-${openItem}`]

            this.backgroundHeight = (dropdownElm.clientHeight)
        })
    },
}))

Alpine.data('overlay', (overlayName = null) => ({

    show: window.Alpine.$persist(true).as(`overlay-${overlayName}`),

    init() {
        if (this.show) {
            this.showOverlay()
        }

        this.$watch('show', (value) => {
            if (value) {
                this.showOverlay()
            } else {
                this.hideOverlay()
            }
        })
    },

    showOverlay() {
        this.$el.showModal()
        document.body.classList.add('overflow-hidden')
    },

    hideOverlay() {
        this.$el.close()
        document.body.classList.remove('overflow-hidden')
    },    
}))

// Magic: $tooltip
Alpine.magic('tooltip', el => message => {
    let instance = tippy(el, { content: message, trigger: 'manual' })

    instance.show()

    setTimeout(() => {
        instance.hide()

        setTimeout(() => instance.destroy(), 150)
    }, 2000)
})

// Directive: x-tooltip
Alpine.directive('tooltip', (el, { expression }) => {
    tippy(el, { content: expression })
})

Livewire.start()

Livewire.hook('commit', ({ succeed }) => {
    succeed(() => {
        queueMicrotask(() => {
            queueMicrotask(() => {
                refreshImages()
            })
        })
    })
})

/**
 * https://stackoverflow.com/a/77383580/367230
 */
window.base64Decode = (base64EncodedString) => new TextDecoder().decode(Uint8Array.from(atob(base64EncodedString), m => m.charCodeAt(0)))